import common from '../../messages/common';
import { message } from '../core';

let _config;
let _source;

export const initialize = (optionalServices = {}) => {
  _config = {
    ...optionalServices,
  };

  return Promise.resolve();
};

export const getConfig = apiName => {
  return _config[apiName];
};

export const handleError = msg => {
  message.error(msg);
};

export const setSource = source => {
  _source = source;
};

export const getSource = () => _source;

export const API = {
  initialize,
  getConfig,
  getSource,
  setSource,
  handleError,
  ...common,
};
