import { find, get, isUndefined, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Checkbox as CoreCheckbox } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from '../useIsFieldAllowed';
import { ValidationText } from '../validation-text/ValidationText';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Checkbox = React.forwardRef((_props, ref) => {
  const formContext = useFormContext();
  const { disabled: formDisabled, isSubmitting, setFieldValue, values } = formContext;
  const isFieldAllowed = useIsFieldAllowed();

  const { fieldConfig, options, isRequired, ...props } = _props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };

  const prerequisitesMet = React.useMemo(() => {
    if (fieldConfig?.prerequisites) {
      return fieldConfig.prerequisites.every(item => !!values[item]);
    }
    return true;
  }, [fieldConfig.prerequisites, values]);

  const disabled = React.useMemo(() => {
    return isSubmitting || !prerequisitesMet;
  }, [isSubmitting, prerequisitesMet]);

  const handleChange = React.useCallback(
    value => {
      setFieldValue(fieldConfig.path, value);
    },
    [fieldConfig, setFieldValue]
  );

  const value = React.useMemo(() => {
    return get(values, fieldConfig.path);
  }, [fieldConfig.path, values]);

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled {...dataTestId}>
      <div className={cn('label-element', { horizontal: fieldConfig?.orientation === 'horizontal' })}>
        <Label
          stringId={fieldConfig.labelStringId}
          required={fieldConfig.required}
          disabled={disabled}
          testId={fieldConfig.testId}
        >
          <CoreCheckbox
            innerRef={ref}
            {...props}
            onChange={handleChange}
            value={value}
            disabled={formDisabled || disabled}
            testId={fieldConfig.testId}
          />
        </Label>
      </div>
      <ValidationText path={fieldConfig.path} name={fieldConfig.name} />
    </Styled>
  );
});
