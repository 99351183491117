import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'users',
  labelStringId: 'users',
  testId: 'users',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    emailOrPhoneNumber: Yup.string().required('required'),
    role: Yup.object().required('required'),
  }),

  fields: [
    { path: 'lastName', labelStringId: 'family_name', testId: 'family_name', dataType: Form.FIELD_TYPES.string },
    { path: 'firstName', labelStringId: 'given_name', testId: 'given_name', dataType: Form.FIELD_TYPES.string },
    { path: 'emailOrPhoneNumber', labelStringId: 'emailOrPhoneNumber', testId: 'emailOrPhoneNumber', dataType: Form.FIELD_TYPES.string },
    {
      path: 'role',
      valuePath: 'roleRef',
      displayPath: 'description',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'role',
      testId: 'role',
    },
  ],
};
