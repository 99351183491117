import React, { useContext } from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { usePostMessageListener } from './usePostMessageListener';

const EntityContext = React.createContext();

export const useEntityContext = () => {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error(`useEntity cannot be rendered outside of the EntityContext context provider`);
  }
  return context;
};

export const AppEntityContextProvider = ({ children }) => {
  const [_entityRef, _setEntityRef] = React.useState({});
  const { sendMessage } = usePostMessageContext();
  const [subscriberCount, setSubscriberCount] = React.useState(0);

  React.useEffect(() => {
    sendMessage({ type: 'cx-entity-change', entityRef: _entityRef?.value });
  }, [_entityRef, sendMessage]);

  const listener = React.useCallback(message => {
    if (message?.type === 'cx-entity-subscribe') {
      setSubscriberCount(s => s + 1);
      _setEntityRef(s => ({ ...s }));
    }
    if (message?.type === 'cx-entity-unsubscribe') {
      setSubscriberCount(s => s - 1);
    }
  }, []);

  usePostMessageListener(listener);

  const reset = React.useCallback(() => {
    setSubscriberCount(0);
  }, []);

  const setEntityRef = React.useCallback(value => {
    _setEntityRef({ value });
  }, []);

  return (
    <EntityContext.Provider value={{ enabled: subscriberCount > 0, setEntityRef, entityRef: _entityRef?.value, reset }}>
      {children}
    </EntityContext.Provider>
  );
};
