import React, { useContext } from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { usePostMessageListener } from './usePostMessageListener';

const EntityContext = React.createContext();

export const useEntityContext = () => {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error(`useEntityContext cannot be rendered outside of the EntityContext context provider`);
  }
  return context;
};

export const EntityContextProvider = ({ children }) => {
  const { sendMessage } = usePostMessageContext();
  const [entityRef, setEntityRef] = React.useState();
  const [previousEntityRef, setPreviousEntityRef] = React.useState();

  React.useEffect(() => {
    sendMessage({ type: 'cx-entity-subscribe' });
    return () => {
      sendMessage({ type: 'cx-entity-unsubscribe' });
    };
  }, [sendMessage]);

  const listener = React.useCallback(
    message => {
      if (message?.type === 'cx-entity-change') {
        setPreviousEntityRef(entityRef);
        setEntityRef(message.entityRef);
      }
    },
    [entityRef]
  );

  usePostMessageListener(listener);

  return <EntityContext.Provider value={{ entityRef, previousEntityRef }}>{children}</EntityContext.Provider>;
};
