import { Form, Drawer } from 'connex-cds';
import React from 'react';
import { users } from '../../query-hooks';
import config from './config';
import { InviteUser } from './invite-user/InviteUser';
import { UserEditor } from './UserEditor';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const View = () => {
  const { openDrawer } = Drawer.useDrawerContext();

  const listUsers = users.useListUsers();
  const updateUser = users.useUpdateUser();

  const customActions = React.useMemo(() => {
    const onClick = () => {
      openDrawer?.({
        titleStringId: `inviteUser`,
        component: <InviteUser />,
      });
    };

    return [{ stringId: 'inviteUser', testId: 'invite-user', onClick: onClick }];
  }, []);

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.DRAWER}
      Editor={UserEditor}
      showHeader={false}
      config={config}
      listQuery={listUsers}
      updateQuery={updateUser}
      customActions={customActions}
    />
  );
};
