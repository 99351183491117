import { isObject } from 'lodash';
import React from 'react';

export const usePostMessageListener = handler => {
  const handleEvent = React.useCallback(
    event => {
      if (isObject(event?.data)) {
        handler(event?.data);
      } else {
        try {
          handler(JSON.parse(event?.data));
        } catch (e) {
          handler(event?.data);
        }
      }
    },
    [handler]
  );

  React.useEffect(() => {
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [handler]);

  return {};
};
