import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem } from 'connex-cds';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InitializeApi } from './api/InitializeApi';
import messages from './i18n';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { Users } from './views/users/Users';
import { RedirectToEntity } from './views/RedirectToEntity';

API.setSource('cxp');

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem messages={messages} appId="cxp">
            <Routes>
              <Route element={<AuthenticatedRoutes />}>
                <Route path="" element={<RedirectToEntity />}>
                  <Route path=":entityRef">
                    <Route path="users/*" element={<Users />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
