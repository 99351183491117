import common from '../common';

export default {
  ...common,

  users: 'Users',
  inviteUser: 'Invite User',
  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,
  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  role: 'Role',
  emailOrPhoneNumber: 'Email or Phone Number',
  usersEditor: 'User Editor',
};
