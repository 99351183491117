import React from 'react';
import { usePostMessageContext } from '../post-message/PostMessage';

export const useSendMessagesToConnex = (messages, isIframe, appId) => {
  const { sendMessage } = usePostMessageContext();

  React.useEffect(() => {
    if (isIframe) sendMessage({ type: 'messages', messages, appId });
  }, [appId, isIframe, messages, sendMessage]);
};
