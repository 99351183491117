import { get, isUndefined } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { NumberInput } from '../../core';
import { Column } from '../../layout';
import { Translate } from '../../localization';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from '../useIsFieldAllowed';
import { ValidationText } from '../validation-text/ValidationText';

import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Quantity = React.forwardRef((props, ref) => {
  const { values, setFieldValue, disabled, isSubmitting, errors, status, handleBlur } = useFormContext();
  const { fieldConfig, isRequired, uomCode } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };
  const isFieldAllowed = useIsFieldAllowed();

  const path = React.useMemo(() => {
    return fieldConfig?.path
      .split('.')
      .concat([fieldConfig?.valuePath])
      .filter(x => !isUndefined(x));
  }, [fieldConfig?.path, fieldConfig?.valuePath]);

  const value = React.useMemo(() => {
    return get(values, path);
  }, [path, values]);

  const handleQuantityChange = React.useCallback(
    val => {
      const preparedValue = { value: val || '', uomCode: value?.uomCode };
      const customValue = fieldConfig?.onChange?.(preparedValue);
      setFieldValue(fieldConfig.path, customValue || preparedValue);
    },
    [value, fieldConfig, setFieldValue]
  );

  React.useEffect(() => {
    if (uomCode && uomCode !== value?.uomCode) {
      const preparedValue = { ...value, uomCode };
      const customValue = fieldConfig?.onChange?.(preparedValue);
      setFieldValue(fieldConfig.path, customValue || preparedValue);
    }
  }, [uomCode, value, setFieldValue, fieldConfig]);

  const uomStringId = React.useMemo(() => {
    const uom = value?.uomCode || uomCode;
    return uom ? `${value?.uomCode || uomCode}_text` : null;
  }, [uomCode, value?.uomCode]);

  if (!isFieldAllowed(fieldConfig)) return null;

  const numberInputProps = React.useMemo(() => {
    return {
      name: `${fieldConfig.path}.value`,
      onBlur: handleBlur,
      value: value?.value,
      onChange: handleQuantityChange,
      disabled: disabled || isSubmitting || props.disabled,
      testId: `${fieldConfig.testId}-value`,
      minValue: 0,
      addonAfter: uomStringId && <Translate stringId={uomStringId} />,
      'aria-labelledby': `${fieldConfig.name || fieldConfig.path}_label`,
    };
  }, [
    disabled,
    fieldConfig.name,
    fieldConfig.path,
    fieldConfig.testId,
    handleBlur,
    handleQuantityChange,
    isSubmitting,
    props.disabled,
    uomStringId,
    value?.value,
  ]);

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.labelStringId}
        testId={`${fieldConfig.testId}`}
        isRequired={isRequired && fieldConfig?.showRequiredIndicator !== false}
        disabled={disabled || isSubmitting || props.disabled}
        id={`${fieldConfig.name || fieldConfig.path}_label`}
      />
      <div className="controls">
        <Column>
          <NumberInput {...numberInputProps} />
          <ValidationText
            path={`${fieldConfig?.path}.value`}
            errors={errors}
            status={status}
            statusPath={fieldConfig?.path}
            name={fieldConfig.name}
          />
        </Column>
      </div>
    </Styled>
  );
});
