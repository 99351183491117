import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useClearMenu } from '../../../util/useMenu';
import { Column } from '../../layout';
import { Translate } from '../../localization';
import { H2 } from '../../typography';

const Styled = styled(Column)`
  align-items: center;
  margin-top: 20%;

  .info {
    display: flex;
    align-items: center;
    gap: 10px;
    .icon {
      color: var(--color-icon-alert-warning);
    }
  }
`;

export const NotAuthorized = props => {
  useClearMenu();

  return (
    <Styled className={cn('not-authorized')}>
      <H2>
        <div className="info">
          <i className="icon fa-solid fa-triangle-exclamation" />
          <Translate stringId="notAuthorized" data-testid="not-authorized" />
        </div>
      </H2>
    </Styled>
  );
};
