import React from 'react';
import { cacheItem, getCachedItem } from '../../util/cache';
import { UserContext } from './UserContext';

export const USER_KEY = '73804c78-c9d6-4673-8ad0-b96d1ef08ff2';

export const UserProvider = ({ children }) => {
  const cachedUser = getCachedItem(USER_KEY);

  const [user, _setUser] = React.useState(cachedUser ? JSON.parse(cachedUser) : null);

  const setUser = React.useCallback(user => {
    if (user) {
      const apps = [].concat([...(user?.apps || [])]); //['mt'];

      if (user?.email?.endsWith?.('commandalkon.com')) {
        apps.push({ name: 'Platform Admin', crn: 'admin' });
        apps.push({ name: 'Company Admin', crn: 'company-admin' });
      }

      user.apps = apps;

      cacheItem(USER_KEY, JSON.stringify(user));
      _setUser(user);
    }
  }, []);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
