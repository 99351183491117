import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { TouchTimePicker as CoreTouchTimePicker } from '../../core';
import getPath from '../../../util/getPath';
import { useLocalizationContext } from '../../localization';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from '../useIsFieldAllowed';
import { ValidationText } from '../validation-text/ValidationText';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const TouchTimePicker = React.forwardRef((props, ref) => {
  const { timeZone } = useLocalizationContext();
  const formContext = useFormContext();
  const { isSubmitting, setFieldValue, disabled, handleBlur, values, validationSchema, formConfig } = formContext;
  const { fieldConfig, isRequired, local, onChange, onDone, rolloverDays, showLabel = true } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };
  const isFieldAllowed = useIsFieldAllowed();

  const handleChange = React.useCallback(
    value => {
      onChange(value);
      onDone?.();
    },
    [onChange, onDone]
  );

  const value = React.useMemo(() => {
    const path = getPath(fieldConfig);
    return get(values, path, '');
  }, [fieldConfig, values]);

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled {...dataTestId}>
      {showLabel ? (
        <Label
          stringId={fieldConfig.labelStringId}
          data-testid={fieldConfig.testId}
          isRequired={isRequired && fieldConfig?.showRequiredIndicator !== false}
          disabled={disabled || isSubmitting || props.disabled}
        />
      ) : null}
      <CoreTouchTimePicker
        name={fieldConfig.path}
        ref={ref}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled || isSubmitting || props.disabled}
        testId={`${fieldConfig.testId}-form-field`}
        local={local}
        timeZone={timeZone}
        rolloverDays={rolloverDays}
      />
      <ValidationText path={fieldConfig.path} name={fieldConfig.name} />
    </Styled>
  );
});
