import React from 'react';
import { cacheItem, clearCachedItem, getCachedItem } from '../../util/cache';
import { USER_KEY, useUserContext } from '../user';
import { AuthContext } from './AuthContext';

/**
 * Renders children when user is authenticated, otherwise render the given unauthenticatedComponent
 *
 * @param children
 * @param unauthenticatedComponent
 * @param onLogin
 * @param noAuthCheck
 * @returns {*}
 * @constructor
 */
export const AuthProvider = ({ children, onLogin, noAuthCheck }) => {
  const [isUserAuthenticated, _setIsUserAuthenticated] = React.useState(!!getCachedItem('x-connex-id'));
  const [authorizedEntity, setAuthorizedEntity] = React.useState(null);
  const userContext = useUserContext();

  const setAuthentication = React.useCallback(loginResponse => {
    cacheItem('x-connex-id', loginResponse?.access_token);
    _setIsUserAuthenticated(true);
  }, []);

  const login = React.useCallback(
    async ({ credentials }) => {
      try {
        const rawResponse = await onLogin({ ...credentials });

        const response = {
          ...rawResponse,
          apps: rawResponse?.apps?.filter?.(x => !!x) || [],
          entities: rawResponse?.entities?.filter?.(x => !!x) || [],
        };

        userContext?.setUser?.(response);

        setAuthentication(response);
        return response;
      } catch (e) {
        console.log('e', e);
        console.log(e.stack);
      }
    },
    [onLogin, setAuthentication, userContext]
  );

  const logout = React.useCallback(() => {
    clearCachedItem('x-connex-id');
    clearCachedItem(USER_KEY);
    _setIsUserAuthenticated(false);
    setAuthorizedEntity(null);
  }, []);

  const isUserAuthorizedForEntity = React.useCallback(
    entityRef => {
      return entityRef === authorizedEntity;
    },
    [authorizedEntity]
  );

  if (noAuthCheck) return children;

  return (
    <AuthContext.Provider
      value={{ login, logout, isUserAuthenticated, isUserAuthorizedForEntity, setAuthorizedEntity }}
    >
      {children}
    </AuthContext.Provider>
  );
};
