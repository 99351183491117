import { css } from 'styled-components';

export default css`
  &&&.touch-time-picker {
    width: 195px;
    margin-left: auto;
    margin-right: auto;

    &.wide {
      width: 195px;
    }

    .day {
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 15px;
      padding: 2px 5px;
      margin-bottom: 10px;

      &.changed {
        background-color: #eaeaa1;
      }
    }

    .time-entry {
      display: flex;
      justify-content: center;
      align-items: center;

      .colon {
        margin: 0 10px;
        font-size: 23px;
      }
    }

    .time-zone {
      text-align: center;

      .pill {
        border: 1px solid #ccc;
        border-radius: 15px;
        padding: 2px 5px;
        //.icon {
        //  transform: rotate(23.5deg);
        //}
        .time-zone-text {
          opacity: 0.8;
          padding-left: 10px;
          padding-right: 5px;
        }
      }
    }

    .actions {
      text-align: center;
      margin: 20px 0;

      button {
        width: 100%;
      }
    }
  }

  &&&.time-part {
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }

    button {
      width: 50px;
      font-size: 1.2rem;
      border-radius: 4px;
      border: 1px solid var(--time-picker-button-border-color);
      background-color: var(--time-picker-button-background-color);
      color: var(--time-picker-button-color);
    }

    input,
    input.number-input {
      width: 50px;
      text-align: center;
      font-size: 1.2rem;
      outline: none;
      padding: 4px 11px;
      border-radius: 4px;
      height: 35px;
      border: 1px solid var(--color-border-input);
      font-weight: 400;
      background-color: var(--color-background-input);
      color: var(--color-text-input);
    }
  }

  &&&.day-period {
    position: relative;
    height: 123px;
    width: 50px;
    margin-left: 20px;

    .frame {
      width: 50px;
      height: 35px;
      font-size: 1.2rem;
      border-radius: 4px;
      position: absolute;
      top: 44px;
      border: 1px solid var(--time-picker-button-border-color);
    }

    .selector {
      position: absolute;
      transition: all 0.3s;
      top: 0;

      & > *:not(:last-child) {
        margin-bottom: 10px;
      }

      &.am {
        top: 44px;
      }

      .ampm {
        width: 50px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        white-space: nowrap;
        border-radius: 4px;
        border: 1px solid var(--time-picker-button-border-color);
        background-color: var(--time-picker-button-background-color);
        color: var(--time-picker-button-color);

        &.active {
          border-color: transparent;
          background-color: transparent;
        }
      }
    }
  }
`;
