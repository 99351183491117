import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useFormContext } from '../../../context-provider/FormProvider';
import { MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';

const Styled = styled.div``;

export const CustomListComponent = ({ onClick, Component }) => {
  const { fieldConfig, setMode, value, activeRowId, setActiveRowId } = useArrayContext();

  const { resetForm, dirty } = useFormContext();

  const columnDef = React.useMemo(() => {
    const groups = fieldConfig?.arrayOf?.groups || [{ fields: fieldConfig?.arrayOf?.fields }];

    return groups.reduce((acc, group) => {
      const fields = group.fields?.map?.(item => ({
        ...item,
        path: [item.path, item.displayPath].filter(x => !!x).join('.'),
        nameStringId: item?.labelStringId,
      }));

      return [...acc, ...fields];
    }, []);
  }, [fieldConfig?.arrayOf]);

  const handleRowClick = React.useCallback(
    row => {
      setMode(MODES.EDIT);
      resetForm({ values: row });
      onClick?.(row);
      setActiveRowId(row?.___id);
    },
    [setMode, resetForm, onClick, setActiveRowId]
  );

  const rowClickDisabled = React.useMemo(() => {
    return dirty; //mode !== null;
  }, [dirty]);

  return (
    <Styled className={cn('list-component')}>
      <Component
        columns={columnDef}
        data={value}
        onRowClick={handleRowClick}
        rowClickDisabled={rowClickDisabled}
        data-testid={fieldConfig?.path || fieldConfig.name}
        keyPath={fieldConfig.keyPath || '___id'}
        activeRowId={activeRowId}
      />
    </Styled>
  );
};
