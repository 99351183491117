import { find } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { useSendMessagesToConnex } from '../../util/useSendMessagesToConnex';
import { useMergeMessages } from './useMergeMessages';
import { consolidateMessages } from './util/consolidateMessages';
// should be passed in : import { messages as defaultGlobalMessages } from '../../i18n/messages-global';

export const LocalizationContext = React.createContext();

export const useLocalizationContext = () => {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error('useIntlContext cannot be called outside of the IntlContext context provider');
  }
  return context;
};

const cachedLanguageId = localStorage.getItem('language');

console.log('cachedLanguageId', cachedLanguageId);

const browserLanguageId = navigator?.languages?.[0] || navigator?.language || 'en-US';

console.log('browserLanguageId', browserLanguageId);

export const Localization = ({ initialLocale, children, messages: msgs, isIframe, appId }) => {
  const mergedMessages = useMergeMessages(msgs);

  useSendMessagesToConnex(msgs, isIframe, appId);

  const supportedLanguages = React.useMemo(() => {
    return Object.keys(mergedMessages);
  }, [mergedMessages]);

  const supportedCachedLanguageId = React.useMemo(() => {
    try {
      const known =
        cachedLanguageId && find(supportedLanguages, l => l.toLowerCase() === cachedLanguageId.toLowerCase());
      return known ? known : null;
    } catch (e) {
      console.log('supportedCachedLanguageId', e?.message);
    }
  }, [supportedLanguages]);

  const supportedBrowserLanguageId = React.useMemo(() => {
    try {
      const known = find(supportedLanguages, l => l.toLowerCase() === browserLanguageId.toLowerCase()) || 'en-US';
      return known ? known : null;
    } catch (e) {
      console.log('supportedBrowserLanguageId', e.message);
    }
  }, [supportedLanguages]);

  const [editMode, setEditMode] = React.useState(false);
  const [userLocale, _setUserLocale] = useState(
    initialLocale || supportedCachedLanguageId || supportedBrowserLanguageId
  );
  const [timeZone, setTimeZone] = React.useState('America/Chicago');
  const [consolidatedMessages, setConsolidatedMessages] = React.useState(
    consolidateMessages({ messages: mergedMessages })[userLocale]
  );

  const { sendMessage } = usePostMessageContext();

  const setUserLocale = React.useCallback((locale, persist = true) => {
    if (locale && persist) {
      localStorage.setItem('language', locale);
    }
    _setUserLocale(locale);
  }, []);

  useEffect(() => {
    try {
      setConsolidatedMessages(consolidateMessages({ messages: mergedMessages })[userLocale]);
      if (!isIframe) {
        sendMessage({ language: userLocale });
      }
    } catch (e) {
      console.log('ue', e.message);
    }
  }, [isIframe, mergedMessages, sendMessage, userLocale]);

  return (
    <LocalizationContext.Provider
      value={{
        userLocale: userLocale || 'en-US',
        setUserLocale,
        editMode,
        setEditMode,
        timeZone,
        setTimeZone,
        supportedLanguages,
      }}
    >
      <IntlProvider locale={userLocale || 'en-US'} messages={consolidatedMessages}>
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  );
};
